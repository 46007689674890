import React from "react";
import logo from "ps-assets/img/logo.png";
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout.jsx";
import PropTypes from "prop-types";
import Carousel from "react-slick";
import AllpoliciesStyle from "ps-assets/jss/allpoliciesStyle.jsx";
import classNames from "classnames";
import root from "window-or-global";
import withStyles from "@material-ui/core/styles/withStyles";
import cashbagicon from "ps-assets/img/icons/cash-bag.png";
import friendsicon from "ps-assets/img/icons/friends.png";
import charticon from "ps-assets/img/icons/chart.png";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Hidden from '@material-ui/core/Hidden';
import senioricon from "ps-assets/img/icons/senior.png";
import coinsicon from "ps-assets/img/icons/coins.png";
import bedicon from "ps-assets/img/icons/bed.png";
import backgroundimage from "ps-assets/img/table-bg.jpg";
import cardicon from "ps-assets/img/icons/card.png";
import homeicon from "ps-assets/img/icons/home.png";
import img1 from "ps-assets/img/img01.jpg";
import img2 from "ps-assets/img/img02.jpg";
import img3 from "ps-assets/img/img03.jpg";
import img4 from "ps-assets/img/mob_img01.jpg";
import img5 from "ps-assets/img/mob_img02.jpg";
import img6 from "ps-assets/img/mob_img03.jpg";
import { landingPageLMSCall } from "util/lmsRequest";
import {  browserWindow } from "util/localStorageHelper";
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;

const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  mobile: `^[6-9][0-9]{9}$`,
  pincode: `^[1-9][0-9]{5}$`,
};

const bgImages = [img1, img2, img3];
const bgImages1 = [img4, img5, img6];
class Allpolicies extends React.Component {
  state = {
    name: ``,
    email: ``,
    mobile: ``,
    pincode: ``,
    privacyPolicy: false,
    submitButton: false,
    // width: browserWindow.innerWidth,
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case `name`:
          this.setState({ [e.target.id]: e.target.value });
        break;
      case `pincode`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `mobile`:
        if (!isNaN(e.target.value) && e.target.value.length <= 10) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `otpNumber`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value, otpError: `` });
        }
        break;
      default:
        this.setState({ [e.target.id]: e.target.value });
        break;
    }
  };

  handlePrivacyPolicy = () => {
    this.setState({
      privacyPolicy: !this.state.privacyPolicy,
    });
  };

  sendToLMS = async () => {
    try {
      let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
      let response = await landingPageLMSCall(
        this.state.name,
        this.state.mobile,
        this.state.email,
        this.state.pincode
      );
      response = await response.json();
      sessionStorage.setItem(`prevLandingPage`, `all-policies`);
      root.open(
        `${browserWindow.location.pathname}/thank-you?userId=${response.zohoEntityId}${queryParams ? `&${queryParams}` : ``
        }`,
        `_self`
      );
    } catch (exception) {
      console.error(`except - `, exception);
    }
  };
  // updateDimensions = () => {
  //   this.setState({ width: browserWindow.innerWidth });
  // };
  // componentDidMount() {
  //   browserWindow.addEventListener("resize", this.updateDimensions);
  // }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitButton: true,
      otpError: ``,
    });
    if (this.state.name !== ``) {
      if (this.state.email.search(/^\S+@\S+\.\S+$/) === 0) {
        if (this.state.mobile.search(validationConditions.mobile)==0) {
          if (this.state.privacyPolicy) {
            if (this.state.pincode.search(validationConditions.pincode)==0) {
              this.sendToLMS();
            }
          }
        }
      }
    }
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      autoplaySpeed: 7000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      dotsClass: `vertical-dots`,
      adaptiveHeight: true,
      beforeChange: (current, next) => {
        this.currentSlide = next;
      },
    };
    const { classes } = this.props;
    return (
      <InstantBuyLayout noHeader={true} noFooter={true}>
        <div className={classes.headercontainer}>
          <div>
            <img src={logo} className={classes.brand} alt="Star Health" />
          </div>
        </div>
        {/* {alert("Your screen resolution is: " + screen.width + "x" + screen.height)} */}

        <Hidden xsDown>
        <div id="health-companion">
          <div id="carousel" data-ride="carousel">
            <div
              className={classes.carousel_inner}
            // style={{ position: "relative !important" }}
            >
              <div
                className={classes.item1}
              // style={{ position: "relative !important" }}
              >
                <div
                  className={classes.carousel}
                // style={{ position: "relative !important" }}
                >
                    <Carousel
                      {...settings}
                    // style={{ position: "relative !important" }}
                    >
                      {bgImages.map((img, id) => (
                        <img key={id} src={img} alt="Star Health"
                        //  style={{width:'255px'}} 
                        style={{    width: '-webkit-fill-available',
                          height: 'fit-content'}}
                         />
                      ))}
                    </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Hidden>
        <Hidden smUp>
        <div id="health-companion">
          <div id="carousel" data-ride="carousel">
            <div
              className={classes.carousel_inner}
            // style={{ position: "relative !important" }}
            >
              <div
                className={classes.item1}
              // style={{ position: "relative !important" }}
              >
                <div
                  className={classes.carousel}>
                    <Carousel {...settings}>
                      {bgImages1.map((img, id) => (
                        <img key={id} src={img} alt="Star Health" />
                      ))}
                    </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Hidden>
        <div className={classes.onimagetext}>
          <div>STAR HEALTH INSURANCE</div>
          <p className={classes.content}>YOUR PERFECT</p>
          <p className={`${classes.health_content} ${classes.healthposition}`}>
            HEALTH COMPANION
          </p>
        </div>

        <div className={classes.container}>
          <div>
            <div className={classes.secondcontainerstyle}>
              <h4 className={classes.iconHead}>WHY STAR HEALTH?</h4>
              <div
                style={{
                  marginLeft: "4%",
                  marginRight: "5%",
                }}
              >
                <div
                  className={classes.listcontentdiv}
                >
                  {[
                    {
                      image: cashbagicon,
                      alt: "Cash_bag_icon",
                      text: "75 Lakh+ Claims settled since inception",
                    },
                    {
                      image: friendsicon,
                      alt: "friends_icon",
                      text:
                        "Hassle free and customer friendly direct claim settlement without intervention of TPA",
                    },
                    {
                      image: charticon,
                      alt: "chart_icon",
                      text:
                        "Upto 100% increase in sum insured upon a claim free renewal",
                    },
                    {
                      image: senioricon,
                      alt: "senior_icon",
                      text: `Wide range of health insurance products from family
                      floater schemes to senior citizen health coverage`,
                    },
                  ].map((data, index) => (
                    <div
                      key={`div-1-${index}`}
                      className={classes.marginPosition}
                    >
                      <img
                        src={data.image}
                        className={classes.health_companion_spriteicon}
                        alt={data.alt}
                      />
                      <p className={classes.icon}>{data.text}</p>
                    </div>
                  ))}
                </div>
                <div
                  className={classes.listcontentdiv}
                >
                  {[
                    {
                      image: homeicon,
                      alt: "home_icon",
                      text:
                        "Largest network hospitals base of over "+HOSPITALCOUNT+" hospitals",
                    },
                    {
                      image: cardicon,
                      alt: "card_icon",
                      text:
                        "89.9% of all cashless claim approval done within one hour",
                    },
                    {
                      image: bedicon,
                      alt: "bed_icon",
                      text: "No cap no treatment cost and room rent",
                    },
                    {
                      image: coinsicon,
                      alt: "coins_icon",
                      text: `Rs. 30,385 Crs of claims amount paid out since inception
                        out since inception`,
                    },
                  ].map((data, index) => (
                    <div
                      key={`div-2-${index}`}
                      className={classes.secondIconPosition}
                    >
                      <img
                        src={data.image}
                        className={classes.health_companion_spriteicon}
                        alt={data.alt}
                      />
                      <p className={classes.icon}>{data.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={classes.position}>
              <h4
                className={classes.positionHead}
              >
                Please Fill in Your Details to Get a Free Quote
              </h4>
              <form
                onSubmit={this.handleSubmit}
                className={classes.formdiv}
                method="post"
                action="/prospect"
              >
                <div className={classes.positionValue}>
                  <input type="hidden" name="urlName" value="13" />
                  <input
                    type="text"
                    className={classNames(
                      classes.form_control,
                      classes.positionInput
                    )}
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    placeholder="Name"
                  />
                  {this.state.submitButton && this.state.name.length<3 && (
                    <p>Name is not in the required format</p>
                  )}
                </div>
                <div className={classes.positionValue}>
                  <input
                    type="email"
                    className={classNames(
                      classes.form_control,
                      classes.positionInput
                    )}
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Email"
                  />
                  {this.state.submitButton &&
                    this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
                    <p>Please enter a valid email address.</p>
                  )}
                </div>
                <div className={classes.positionValue}>
                  <input
                    type="text"
                    className={classNames(
                      classes.form_control,
                      classes.positionInput
                    )}
                    name="mobile"
                    id="mobile"
                    value={this.state.mobile}
                    onChange={this.handleChange}
                    placeholder="Mobile"
                  />
                  {((this.state.submitButton && this.state.mobile.length < 10) || (this.state.submitButton && this.state.mobile.search(`^[6-9][0-9]{9}$`) == -1)) && (
                    <p>Enter 10 Digit Phone Number</p>
                  )}
                </div>
                <div className={classes.positionValue}>
                  <input
                    type="text"
                    className={classNames(
                      classes.form_control,
                      classes.positionInput
                    )}
                    name="pincode"
                    id="pincode"
                    value={this.state.pincode}
                    onChange={this.handleChange}
                    placeholder="PinCode"
                  />
                  {((this.state.submitButton && this.state.pincode.length < 6) || (this.state.submitButton && this.state.pincode.search(`^[1-9][0-9]{5}$`) == -1)) && (
                    <p>Enter 6 Digit Valid PinCode</p>
                  )}
                  {/* <TextField maxLength={6} 
             error={!this.state.formDataVal['pinCode'].isValid  && this.state.formDataVal['pinCode'].value.length>0}
              variant="outlined" 
              className={classes.textfield}
              value={this.state.formDataVal['pinCode'].value} 
              name="pinCode" id="pinCode"
              onBlur={this.onClickValidation}
              onChange={this.onChangeHandler} 
              label="Pincode" 
              placeholder='Enter Valid 6 digit no'/> */}
                  {/* <select
                    name="city"
                    className={classNames(
                      classes.form_control,
                      classes.positionInput
                    )}
                    id="city"
                    title="Please select any city"
                    value={this.state.city}
                    onChange={this.handleChange}
                  >
                    <option value="">Select City</option>
                    <option value="Agra">Agra</option>
                    <option value="Ahmed Nagar">Ahmed Nagar</option>
                    <option value="Ahmedabad">Ahmedabad</option>
                    <option value="Alappuzha">Alappuzha</option>
                    <option value="Allahabad">Allahabad</option>
                    <option value="Amritsar">Amritsar</option>
                    <option value="Ananthapur">Ananthapur</option>
                    <option value="Aurangabad">Aurangabad</option>
                    <option value="Bangalore">Bangalore</option>
                    <option value="Bangarpet">Bangarpet</option>
                    <option value="Bareilly">Bareilly</option>
                    <option value="Baroda">Baroda</option>
                    <option value="Belgaum">Belgaum</option>
                    <option value="Bellary">Bellary</option>
                    <option value="Bhopal">Bhopal</option>
                    <option value="Bhubaneswar">Bhubaneswar</option>
                    <option value="Bilaspur">Bilaspur</option>
                    <option value="Calicut">Calicut</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chennai">Chennai</option>
                    <option value="Cochin">Cochin</option>
                    <option value="Coimbatore">Coimbatore</option>
                    <option value="Cuddalore">Cuddalore</option>
                    <option value="Cuttack">Cuttack</option>
                    <option value="Davangare">Davangare</option>
                    <option value="Dehradun">Dehradun</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Dhanbad">Dhanbad</option>
                    <option value="Dharmapuri">Dharmapuri</option>
                    <option value="Dindigul">Dindigul</option>
                    <option value="Durgapur">Durgapur</option>
                    <option value="Eluru">Eluru</option>
                    <option value="Ernakulam">Ernakulam</option>
                    <option value="Erode">Erode</option>
                    <option value="Gobichetipalayam">Gobichetipalayam</option>
                    <option value="Gulbarga">Gulbarga</option>
                    <option value="Guntur">Guntur</option>
                    <option value="Gurgaon">Gurgaon</option>
                    <option value="Guwahati">Guwahati</option>
                    <option value="Gwalior">Gwalior</option>
                    <option value="Hosur">Hosur</option>
                    <option value="Howrah">Howrah</option>
                    <option value="Hubli">Hubli</option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Indore">Indore</option>
                    <option value="Jabalpur">Jabalpur</option>
                    <option value="Jaipur">Jaipur</option>
                    <option value="Jalandhar">Jalandhar</option>
                    <option value="Jammu">Jammu</option>
                    <option value="Jamshedpur">Jamshedpur</option>
                    <option value="Jhansi">Jhansi</option>
                    <option value="Jodhpur">Jodhpur</option>
                    <option value="Kakinada">Kakinada</option>
                    <option value="Kalpetta">Kalpetta</option>
                    <option value="Kannur">Kannur</option>
                    <option value="Kanpur">Kanpur</option>
                    <option value="Karnal">Karnal</option>
                    <option value="Karur">Karur</option>
                    <option value="Kolhapur">Kolhapur</option>
                    <option value="Kolkata">Kolkata</option>
                    <option value="Kota">Kota</option>
                    <option value="Kottayam">Kottayam</option>
                    <option value="Kumbakonam">Kumbakonam</option>
                    <option value="Lucknow">Lucknow</option>
                    <option value="Ludhiana">Ludhiana</option>
                    <option value="Machillipatnam">Machillipatnam</option>
                    <option value="Madurai">Madurai</option>
                    <option value="Malappuram">Malappuram</option>
                    <option value="Mangalore">Mangalore</option>
                    <option value="Mettupalayam">Mettupalayam</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Mysore">Mysore</option>
                    <option value="Nagercoil">Nagercoil</option>
                    <option value="Nagpur">Nagpur</option>
                    <option value="Namakkal">Namakkal</option>
                    <option value="Nashik">Nashik</option>
                    <option value="Nellore">Nellore</option>
                    <option value="Neyyatinkara">Neyyatinkara</option>
                    <option value="Noida">Noida</option>
                    <option value="Ongole">Ongole</option>
                    <option value="Palakkad">Palakkad</option>
                    <option value="Panipat">Panipat</option>
                    <option value="Patiala">Patiala</option>
                    <option value="Patna">Patna</option>
                    <option value="Perundurai">Perundurai</option>
                    <option value="Pitampura">Pitampura</option>
                    <option value="Pondicherry">Pondicherry</option>
                    <option value="Pune">Pune</option>
                    <option value="Raipur">Raipur</option>
                    <option value="Rajahmundry">Rajahmundry</option>
                    <option value="Rajkot">Rajkot</option>
                    <option value="Ramnad">Ramnad</option>
                    <option value="Ranchi">Ranchi</option>
                    <option value="Ratlam">Ratlam</option>
                    <option value="Razole">Razole</option>
                    <option value="Salem">Salem</option>
                    <option value="Serampore">Serampore</option>
                    <option value="Shimoga">Shimoga</option>
                    <option value="Sivagangai">Sivagangai</option>
                    <option value="Surat">Surat</option>
                    <option value="Tanjore">Tanjore</option>
                    <option value="Theni">Theni</option>
                    <option value="Thirunelveli">Thirunelveli</option>
                    <option value="Tirupathi">Tirupathi</option>
                    <option value="Tirupur">Tirupur</option>
                    <option value="Trichy">Trichy</option>
                    <option value="Trivandrum">Trivandrum</option>
                    <option value="Udaipur">Udaipur</option>
                    <option value="Udupi">Udupi</option>
                    <option value="Ujjain">Ujjain</option>
                    <option value="Vaishali">Vaishali</option>
                    <option value="Varanasi">Varanasi</option>
                    <option value="Varkala">Varkala</option>
                    <option value="Vellore">Vellore</option>
                    <option value="Vijayawada">Vijayawada</option>
                    <option value="Villupuram">Villupuram</option>
                    <option value="Virudhunagar">Virudhunagar</option>
                    <option value="Visakhapatnam">Visakhapatnam</option>
                    <option value="Warangal">Warangal</option>
                    <option value="Others">Others</option>
                  </select>
                */}
                </div> 
                <div className={classes.positionValue}>
                  <GridContainer className={classes.Color}>
                    <GridItem md={1} sm={1} xs={1} className={classes.checkBoxGridPosition}>
                      <input
                        type="checkbox"
                        onClick={this.handlePrivacyPolicy}
                        checked={this.state.privacyPolicy}
                      />
                    </GridItem>
                    <GridItem md={10} sm={11} xs={10} className={classes.privacyGridPosition}>
                      {` I have read and agree to the `}
                      <a
                        href="/static-privacy-policy"
                        target="_blank"
                        rel={`noopener noreferrer`}
                        style={{ cursor: `pointer`, textDecoration: `underline` }}
                      >
                      Privacy Policy
                      </a>
                      {` I authorize Star Health Insurance to
                  contact me via email or phone or SMS.`}
                    </GridItem>
                  </GridContainer>
                </div>
                {this.state.submitButton && !this.state.privacyPolicy && (
                  <p style={{ textAlign: `center` }}>
                    Please Agree to the Terms
                  </p>
                )}
                <button type="submit" className={classes.submitButton}>
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </div>
        <GridContainer
          style={{
            backgroundImage: `url(${backgroundimage})`,
          }}
          className={classes.backgroundrepeat}
        >
          <GridItem sm={12} md={6} xs={12} className={classes.offers}>
            <p className={classes.offersline2}>WHAT WE OFFER & OTHERS DONT.</p>
            <table cellPadding="3px" className={classes.tableStyle}>
              <thead>
                <tr className={classes.tableheaderstyle}>
                  <th className={classes.tablerowpadding}>FEATURES</th>
                  <th>STAR HEALTH</th>
                  <th>OTHER INSURERS</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.odd}>
                  <td className={classes.tablerowpadding}>
                    Cashless Approvals
                  </td>
                  <td>1 Hour</td>
                  <td>4 Hours - 1 Day</td>
                </tr>
                <tr className={classes.even}>
                  <td className={classes.tablerowpadding}>
                    Average Claim Settlement
                  </td>
                  <td>15 Days</td>
                  <td>1 Month</td>
                </tr>
                <tr className={classes.odd}>
                  <td className={classes.tablerowpadding}>Cashless Handling</td>
                  <td>In House claim settlement</td>
                  <td>TPA</td>
                </tr>
                <tr className={classes.even}>
                  <td className={classes.tablerowpadding}>Network Hospitals</td>
                  <td>{HOSPITALCOUNT}</td>
                  <td>3000-4000</td>
                </tr>
                <tr className={classes.odd}>
                  <td className={classes.tablerowpadding}>
                    24X7 TeleMedical Assistance
                  </td>
                  <td>Provided</td>
                  <td>Majority of others dont provide.</td>
                </tr>
                <tr className={classes.even}>
                  <td className={classes.tablerowpadding}>
                    Pre Medical Screening
                    <br />
                    Cost
                  </td>
                  <td>Completely Borne by our Company</td>
                  <td>Partially Borne</td>
                </tr>
                <tr className={classes.odd}>
                  <td className={classes.tablerowpadding}>
                    Value Added Services
                  </td>
                  <td>Available</td>
                  <td>NA</td>
                </tr>
                <tr className={classes.even}>
                  <td className={classes.tablerowpadding}>Lifetime Renewal</td>
                  <td>Guaranteed</td>
                  <td>Offered</td>
                </tr>
              </tbody>
            </table>
          </GridItem>
          <GridItem sm={12} md={4} xs={12} className={classes.tablepara}>
            While maintaining our promise of protecting you and your loved ones,
            Star Health Insurance Endeavors to build deep associations with our
            valued customers. We at Star Health Insurance are proud to have
            settled more than
            <span className={classes.tableparaline2}>4.9 Million </span>
            stress free claims for our valued customers. In addition, having
            tied up with more than
            <span className={classes.tableparaline2}>
              {HOSPITALCOUNT} network hopsitals across India,
            </span>
            we have come closer to fulfilling our goal of customer satisfaction.
          </GridItem>
          </GridContainer>
        <div className={classes.footer} style={{fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
          fontSize: '14px',
          lineHeight: '1.42857143',
          color: '#333',
          backgroundColor: '#fff',}}>
          <div>
            <p>
              <b>IRDAI REGISTRATION NUMBER. 129</b>
            </p>

            <p style={{ lineHeight: `20px` }}>
              For complete details on coverage, terms, conditiions and
              exclusions, please read the Policy document carefully.
              <br />
              Insurance is the subject matter of solicitation.
            </p>
          </div>
          <div>
            <p>
              {" "}
              <b>Star Health and Allied Insurance Company Limited.</b>
            </p>

            <p style={{ lineHeight: `20px` }}>
              Corporate Office: No.1, New Tank Street, Valluvar Kottam High
              Road, Nungambakkam, Chennai - 600034. Sales & Services - 044 4674 5800
              <br />
              For further details visit www.starhealth.in |
              SHAI/17-18/Online/LP/BuyOnline/008
            </p>
          </div>
        </div>
      </InstantBuyLayout>
    );
  }
}

Allpolicies.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(AllpoliciesStyle)(Allpolicies);
