// import backgroundimage from "ps-assets/img/table-bg.jpg";
const AllPoliciesStyle = theme => ({
  hearderWrapper: {
    padding: "5px 0px",
  },
  headercontainer: {
    width: "100%",
    paddingLeft: "9%",
    backgroundColor: "white",
    paddingTop: `5px`,
    [theme.breakpoints.down("md")]: {
      paddingBottom: `10px`
    }
  },
  container2style: {
    width: "100%",
    marginTop: "-32%",
    position: "absolute",
    "@media (max-width: 992px)": {
      marginTop: "-10%",
      textAlign: "center",
    },
  },
  container: {
    paddingLeft: "7%",
    paddingRight: "9%",
    width: "100%",
    paddingTop: "1%",
    background: "white",
    paddingBottom: "2%",
    "@media (max-width: 1025px)": {
      paddingTop: "6%",
    },
    "@media (max-width: 770px)": {
      paddingLeft: "5%",
      paddingRight: "9%",
    },
  },
  health_companion_spriteicon1: {
    // backgroundPosition: "-28px -23px",
    height: "80%",
    width: "100%",
    marginTop: "3% !important",
  },
  tablepara: {
    paddingTop: '10%',
    paddingRight: '7%',
    paddingLeft: '30px',
    fontSize: '15px !important',
    lineHeight: '22px',
    marginBottom: '15px',
    color: '#D0BE9A',
    "@media (max-width: 992px)": {
      width: "100%",
      paddingLeft: "0",
      color: "#eaa34e",
      marginTop: "-11%",
      background: "rgba(40, 40, 40, 1)",
      paddingTop: "1%",
    },
    "@media (max-width: 770px)": {
      width: "100%",
      background: "rgba(40, 40, 40, 1)",
      marginLeft:'6%',
      marginRight:'5.9%',
      fontSize: '14px !important',
      marginTop:'-1%',
    fontWeight: '600',
    lineHeight: '23px',
    textAlign: 'left',
    marginBottom: '20px',
    padding: '20px 26px',
    color: '#eaa34e'
    },
    "@media (max-width: 550px)": {
      marginLeft:'6%',
      marginRight:'4.9%',
      marginTop:'-3%',
    },
    "@media (max-width: 400px)": {
      marginLeft:'6%',
      marginRight:'3.9%',
      marginTop:'-3%',
    },
    "@media (max-width: 325px)": {
      marginLeft:'4%',
      marginRight:'0%',
      marginTop:'-3%',
    },
  },
  tableparaline2: {
    color: "#fff",
    fontSize: "21px",
    fontWeight: "500",
    margin: `0px 5px`,
    "@media (max-width: 992px)": {
      fontSize: "15px",
    },
    "@media (max-width: 770px)": {
      fontSize: "21px !important",
    fontWeight: "500"
    },
    
  },
  icon: {
    color: "black",
    fontWeight: "500",
    marginLeft: `1%`,
    marginTop: `auto`,
    marginBottom: `auto`,
    lineHeight: "18px",
    "@media (max-width: 992px)": {
      fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
      fontSize: '14px',
      fontWeight: '600',
      verticalAlign: 'middle',
      marginTop: '10px',
      lineHeight: '18px'
    },
    "@media (max-width: 768px)": {
      fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
      fontSize: '14px',
      fontWeight: '600',
      verticalAlign: 'middle',
      marginTop: '10px',
      lineHeight: '18px'
    },
  },
  // iconline2: {
  //   fontWeight: "600",
  //   verticalAlign: "left",
  //   marginTop: "-12px",
  //   lineHeight: "18px",
  //   marginLeft: "8%",
  // },
  iconline3: {
    fontWeight: "600",
    verticalAlign: "left",
    marginTop: "-1px",
    lineHeight: "18px",
    marginLeft: "8%",
  },
  brand: {
    maxWidth: `none`,
    marginTop: `0.5%`,
    marginBottom: `0.5%`,
  },
  brandbck: {
    width: "100%",
    marginTop: "12px",
  },
  col_md_12: {
    width: "100%",
    // float: "left",
    // position: "relative",
    // minHeight: "1px",
    // paddingRight: "15px",
    // paddingLeft: "15px",
  },
  pull_left: {
    float: "left !important",
    boxSizing: "border-box",
  },
  clear_both: {
    clear: "both",
  },
  carousel: {
    position: "relative",
  },
  carousel_inner: {
    // position: "relative",
    width: "100%",
    // transitionProperty: "opacity",
  },
  item1: {
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    height: "255px",
    // transitionProperty: "opacity",
    "@media (max-width: 1025px)": {
      height: "195px",
    },
    "@media (max-width: 1020px)": {
      height: "255px",
    },    
    "@media (max-width: 768px)": {
      height: "25%",
    },
  },
  item: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "255px",
    transitionProperty: "opacity",
    "@media (max-width: 768px)": {
      height: "25%",
    },
  },
  carousel_fade: {
    color: "white",
    // transitionProperty: "opacity",
  },
  health_companion_content: {
    marginTop: "6%",
    marginBottom: "5%",
  },
  col_md_6: {
    width: "50%",
    //float: "left",
    paddingLeft: "0px",

    listStyle: "none",
  },
  col_md_8: {
    width: "66.66666667%",
    //float:'Left',
    // position: "relative",
    // minHeight: "1px",
    // paddingRight: "15px",
    // paddingLeft: "15px",
  },
  col_md_4: {
    width: "33.33333333%",
  },
  health_companion_spriteicon: {
    //  : "-28px -23px",
    height: "75px",
    float: "left",
    width: "80px",

    // float:'left'
  },
  listcontentdiv: {
    float: "left",
    width: "50%",
    background: "white",
    position: "relative",
    "@media (max-width: 768px)": {
      width: "50% !important",
    },
    "@media (max-width: 550px)": {
      width: "100% !important",
    },
  },
  secondcontainerstyle: {
    width: "70%",
    "@media (max-width: 992px)": {
      width: "100% !important",
    },
    // "@media (max-width: 768px)": {
    //   width: "100% !important",
    // },
  },

  star_companion_form: {
    position: "relative",
    marginTop: "-170px",
    backgroundColor: "#BFEDFC",
  },
  form: {
    padding: "0px 20px",
  },
  unorderliststyle: {
    listStyle: "none",
    display: "inline-block",
    "@media (max-width: 768px)": {
      width: "100% !important",
    },
  },
  form_label: {
    position: "absolute",
    paddingLeft: "15px",
    marginLeft: "0px",
    marginTop: "22px",
    color: "#999",
    lineHeight: "15px",
    fontWeight: "500",
  },
  content: {
    fontSize: "40px",
    lineHeight: "25px",
    color: "#fff",
    // color: "green",
    margin: "0 0 10px",
    "@media (max-width: 992px)": {
      fontSize: "25px",
    },
    "@media (max-width: 768px)": {
      fontSize: "30px !important",
      marginBottom: `0px`,
      fontWeight:'300',
      lineHeight:'28px',
      color:'#fff'
    },
    "@media (max-width: 500px)": {
      fontSize: '16px  !important',
      lineHeight: '20px'
    },
  },
  backgroundrepeat:{
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  height: "100%",
  paddingBottom:'6%',
  display: "inline-flex",
  "@media (max-width: 1025px)": {
    backgroundSize: "100% 100%",
    paddingBottom:'4%',
  },
  "@media (max-width: 959px)": {
    backgroundSize: "100%",
    paddingBottom:'0%',
  },
  "@media (max-width: 760px)": {
    backgroundSize: "100%",
    paddingBottom:'6%',
  },
  },
  onimagetext: {
    color: "white",
    position: "absolute",
    top: "23%",
    left: "10%",
    lineHeight: "30px",
    zIndex: "1",
    fontWeight: 400,
    "& div": {
      fontWeight: 400,
      margin: `5px 0px 8px 0px`,
      fontSize: `20px`,
      "@media (max-width: 956px)": {
        margin: `0px`
      },
      "@media (max-width: 500px)": {
        fontSize: '14px',
        color: '#fff',
        marginBottom: '5px'
      },
    },
    "@media (max-width: 1025px)": {
      fontSize: "18px",
      top: "9%",
      left: "7%",
    },
   
    "@media (max-width: 992px)": {
      fontSize: "18px",
      top: "22%",
      left: "7%",
    },
    // "@media (max-width: 768px)": {
    //   fontSize: "14px !important",
    //   top: "20%",
    //   left: "4%",
    // },
    "@media (max-width: 770px)": {
      fontSize: "18px !important",
      top: "11%",
      left: "6%",
      lineHeight:'32px'
    },
    "@media (max-width: 550px)": {
      // fontSize: "14px !important",
      top: "16%",
      left: '11%'
    },
  },
  healthposition: {
    marginTop: "10px",
    "@media (max-width: 992px)": {
      top: "-30px",
    },
    "@media (max-width: 768px)": {
      top: "-45px",
    },
  },
  marginPosition:{
  display: "inline-flex",
  paddingRight: `15px`,
  marginBottom: `15px`,
  },
  health_content: {
    fontSize: "43px",
    fontWeight: "600",
    lineHeight: "37px",
    color: "#fff",
    margin: "0 0 10px",
    "@media (max-width: 992px)": {
      fontSize: "25px",
    },
    "@media (max-width: 770px)": {
      fontSize: '30px',
    color: '#fff',
    fontWeight: '600',
    lineHeight: '25px'
    },

    "@media (max-width: 500px)": {
      fontSize: '24px !important',
      lineHeight: '27px'
    },

    
  },
  footer: {
    paddingRight: "15px",
    marginTop: "2.5%",
    paddingLeft: "15px",
    paddingBottom:'1.8%',
    marginRight: "auto",
    marginLeft: "auto",
    textAlign: "center",
    marginBottom: `20px`,
    color: `#333`,
    "& p": {
      fontSize: `14px`,
      marginBottom: `0px`,
    },
    "& div": {
      marginBottom: `12px`,
    },
    "@media (max-width: 992px)": {
      marginTop: "4.5%",
    },
    "@media (max-width: 910px)": {
      marginTop: "4.5%",
    },
    "@media (max-width: 770px)": {
      marginTop: "2.5%",
    },
    "@media (max-width: 626px)": {
      marginTop: "4.5%",
    },
    "@media (max-width: 545px)": {
      marginTop: "100.5%",
    },
    "@media (max-width: 466px)": {
      marginTop: "4.5%",
    },
    "@media (max-width: 416px)": {
      marginTop: "2.5%",
    },
    "@media (max-width: 366px)": {
      marginTop: "2.5%",
    },
    "@media (max-width: 336px)": {
      marginTop: "2.5%",
    },
  },
  odd: {
    backgroundColor: "rgba(255,204,153,0.4)",
    color: "#fff",
    fontWeight: "500",
    "@media (max-width: 992px)": {
      backgroundColor: "#7F7059",
    },
  },
  even: {
    backgroundColor: "rgba(255,204,153,0.2)",
    color: "#fff",
    fontWeight: "500",
    "@media (max-width: 992px)": {
      backgroundColor: "#5d5447",
    },
  },
  tablerowpadding: {
    paddingLeft: "20px",
  },
  secondIconPosition:{
  display: "inline-flex",
  marginBottom: `15px`,
  },
  tableStyle: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: "20px",
    textAlign: "left",
    backgroundColor: "transparent",
    borderSpacing: "0px",
    fontSize: "10px",
    borderCollapse: "collapse",
    "@media (max-width: 992px)": {
      backgroundColor: "none",
      marginTop:'13%'
    },
    "@media (max-width: 550px)": {
      marginTop: "5%",
    },
    "@media (max-width: 324px)": {
      marginTop: "0%",
      marginLeft:'-4%'
    },
  },
  offers: {
    width: "53%",
    float: "left",
    marginLeft: "10%",
    marginTop: "4%",
    "@media (max-width: 992px)": {
      position: "static",
      display: "inline-block",
      width: "100% !important",
      marginLeft: "0%",
      marginTop: "0px",
      paddingLeft:'8%',
      paddingRight:'8%'
    },
    "@media (max-width: 325px)": {
      position: "static",
      display: "inline-block",
      width: "100% !important",
      marginLeft: "0%",
      marginTop: "0px",
      paddingLeft:'9%',
      paddingRight:'8%'
    },
  },
  offersline2: {
    fontSize: "30px",
    lineHeight: "30px",
    marginBottom: "1.5%",
    color: "#fff",
    margin: "0 0 10px",
    fontWeight: "400",
    "@media (max-width: 992px)": {
      fontSize: "15px",
    lineHeight: "30px",
    marginBottom: "1.5%",
    color: "#fff",
    margin: "0 0 10px",
    fontWeight: "400",
    },
    "@media (max-width: 770px)": {
      padding: '20px 0px !important',
      textAlign: 'center',
      color: '#fff',
      marginTop:'20%'
    },
    "@media (max-width: 329px)": {
      padding: '20px 0px !important',
      textAlign: 'center',
      color: '#fff',
      marginTop:'20%'
    },
  },
  // tableBackground: {
  //   backgroundImage: `url(${backgroundimage})`,
  //   backgroundRepeat: "no-repeat",
  //   // WebkitBackgroundSize: "100% 100%",
  //   // width: "100%",
  //   marginTop: "11.8%",
  //   maxWidth: "100%",
  //   // height: "45.5%",
  // },
  submitButton: {
    backgroundColor: "#FF714D ",
    color: "white",
    padding: "1% 4.5% ",
    border: "none",
    width: "146px",
    borderRadius: "5px",
    height: "38px",
    marginLeft: "-2px",
    marginTop:'10px',
    cursor: `pointer`,
  },
  Color: {
    color: "black",
    fontSize:'10px',
    lineHeight:'15px',
    "@media (max-width: 770px)": {
      marginTop:'3%',
      paddingBottom:'1%'
  },
  "@media (max-width: 550px)": {
    marginTop:'1%',
    paddingBottom:'1%'
},
  },
  formdiv:{
  paddingLeft: "9%",
  paddingBottom: "9%",
  "@media (max-width: 770px)": {
    paddingLeft: "9%",
  paddingBottom: "4%",
},
"@media (max-width: 550px)": {
  paddingLeft: "9%",
  paddingBottom: "9%",
},
  },
  Acknowledgement: {
    position: "relative",
    marginBottom: "8px",
    fontSize: "14px",
    marginLeft: "-5px",
  },
  positionInput: {
    minHeight: "45px !important",
    width: "94%",
    borderStyle: "none",
    paddingLeft: "5%",
    "@media (max-width: 992px)": {
      paddingLeft:'3%'
    }
  },
  positionValue: {
    position: "relative",
    marginBottom: "8px",
    fontSize: "14px",
  },
  positionHead: {
    paddingTop: `8%`,
    marginLeft: "9%",
    color: "black",
    width: "66%",
    fontWeight: "500",
    fontSize:'21px',
    "@media (max-width: 1025px)": {
      // position: "relative",
      // display: "inline-block",
      width: "84%",
    },
    "@media (max-width: 992px)": {
      // position: "relative",
      // display: "inline-block",
      width: "100%",
      // marginLeft: "2%",
      // marginTop: "0px",
      padding: '5px 20px',
      lineHeight: '25px',
      marginTop: '25px',
      color: '#000',
      marginLeft: '6%',
      fontSize: '21px',
    },
    "@media (max-width: 550px)": {
      marginLeft:'2%'
  },
  },
  checkBoxGridPosition:{
    "@media (max-width: 770px)": {
      paddingRight:'0px',
      paddingLeft:'23px'
  },
  "@media (max-width: 550px)": {
    paddingRight:'15px',
    paddingLeft:'15px'
},
},
  privacyGridPosition:{
    paddingLeft:'10px',
    "@media (max-width: 770px)": {
      paddingLeft:'0px',
    },
    "@media (max-width: 550px)": {
      paddingLeft:'10px',
    },
  },
  position: {
    position: "relative",
    fontSize: "14px",
    backgroundColor: "#BFEDFC",
    width: "33%",
    marginTop: "-255px",
    marginLeft: "67%",
    zIndex: `1`,
    "@media (max-width: 1025px)": {
      marginTop: "-207px",
      marginLeft: "72%",
    },
    "@media (max-width: 992px)": {
      position: "relative",
      display: "inline-block",
      width: "100%",
      marginLeft: "2%",
      marginTop: "0px",
    },
  },
  tableheaderstyle: {
    backgroundColor: "#A26D2E",
    color: "#fff",
    "& th": {
      fontWeight: "500",
      backgroundColor: "#A26D2E",
      padding: '13px 25px',
      fontSize: `14px`,
      "@media (max-width: 325px)": {
        padding: '13px 20px',
        borderLeft:'1px solid white'
      },
    },
  },
  iconHead: {
    marginLeft: "5%",
    color: "#140757",
    fontSize: "30px",
    fontWeight: "400",
    "@media (max-width: 350px)": {
      fontSize: '24px',
    padding: '15px 0px'
    },
    
  },
});
export default AllPoliciesStyle;
